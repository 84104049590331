<template>
  <div>
    <div v-if="!mounted">Chargement...</div>
    <div v-else>
      <!-- Activite du club -->
      <div class="title_activites py-3">
        Sélectionner ses activités dans le menu.
      </div>
      <ValidateForm
        @validated="submit"
        :loading="loading"
        :showFieldObl="false"
      >
        <ActivitesUtilisateurs
          :currentActivites="currentUtilisateurActivites"
          @onChange="activitesChange"
          :noMainActivites="true"
          :noLabel="true"
          :disabled="true"
        />
        <br />
        <v-btn type="submit" :loading="loading" class="btn btn-primary mt-2"
          >Mettre à jour</v-btn
        >
      </ValidateForm>
    </div>
  </div>
</template>

<script>
import ActivitesUtilisateurs from "@/components/Activites/Activites__form.vue";
import ValidateForm from "@/components/utils/form.vue";
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: { ActivitesUtilisateurs, ValidateForm },

  data: () => ({
    mounted: false,
    currentUtilisateurActivites: null,
    loading: false,
    title_section: "Activités pratiquées",
  }),

  async created() {
    this.$emit("setTitle", this.title_section);
    console.log(`this.currentStructure`, this.currentStructure);
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("structure", ["currentStructure"]),
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateurActivites",
      "updateUtilisateurActivites",
    ]),

    async setData() {
      this.currentUtilisateurActivites = [
        ...(await this.getUtilisateurActivites({
          ID_Utilisateur: this.currentUserId,
        })),
      ];
      console.log(
        `this.currentUtilisateurActivites======>>>`,
        this.currentUtilisateurActivites
      );
      return;
    },

    activitesChange(activites) {
      this.currentUtilisateurActivites = activites;
      // console.log(activites);
    },

    async submit() {
      try {
        let activites = this.currentUtilisateurActivites.map((act) => ({
          ID_Utilisateur: this.currentUserId,
          ID_Activite: act.id ?? act.ID_Activite,
          EST_ActivitePrincipale: act.EST_ActivitePrincipale,
          Ordre: act.LIST_ActiviteType
            ? act.LIST_ActiviteType.Ordre
            : act.Ordre,
          LIST_ActiviteType: {
            NomActivite: act.LIST_ActiviteType
              ? act.LIST_ActiviteType.NomActivite
              : act.NomActivite,
            Ordre: act.NomActivite,
            id: act.id ?? act.ID_Activite,
            __typename: "s",
          },
          __typename: "s",
        }));

        await this.updateUtilisateurActivites({
          activites: activites,
          ID_Utilisateur: this.currentUserId,
        });
        await this.setData();
        success_notification("Mis à jour");
        this.loading = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.title_activites {
  font-family: "Roboto";
  font-size: 16px !important;
}

.btn_activites {
  width: 210px;
  height: 34px;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}
</style>
