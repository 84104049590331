<template>
  <UserDetailsForm
    :context="{
      ID_Utilisateur: currentUserId,
      ID_Structure: currentStructureId,
      ID_UtilisateurDemande: currentAuthUserId,
      user: currentUser,
    }"
  />
</template>

<script>
import UserDetailsForm from "@/components/User/Form/User__FormModificationCoordonnees.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ModificationDesCoordonnees",

  components: { UserDetailsForm },

  data: () => ({
    title_section: "Modification des coordonnées",
  }),

  async created() {
    this.$emit("setTitle", this.title_section);
    await this.setCurrentStructureFederal();

    console.log(`this.currentStructureID===>>`, this.currentStructureId);
  },

  computed: {
    ...mapGetters("user", ["currentUserId", "currentUser"]),
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),
    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },
  },

  methods: {
    ...mapActions("structure", ["setCurrentStructureFederal"]),
  },
};
</script>
