<template>
  <div>
    <v-alert
      dense
      color="orange lighten-5"
      class="my-5 info_identifiant"
      icon="info"
    >
      Votre identifiant est :
      <span class="font-weight-bold">{{
        currentUser.CT_PreferredUsername || currentUser.LOG_Login
      }}</span>
    </v-alert>
    <div class="mt-4 text_ifentifiant">
      Pour changer votre identifiant, merci de cliquer sur le bouton suivant:
      <div class="mt-2 ml-3 btn_identifiant">
        <v-btn class="btn btn-primary" :href="updateLink"
          >Changer d'identifiant</v-btn
        >
      </div>
    </div>

    <div class="mt-8 text_ifentifiant">
      Pour changer votre mot de passe, merci de cliquer sur le bouton suivant:
      <div class="mt-2 ml-3 btn_identifiant">
        <v-btn class="btn btn-primary" :href="resetLink"
          >Changer de mot de passe</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    title_section: "Modification identifiant et mot de passe",
  }),

  async created() {
    this.$emit("setTitle", this.title_section);
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),

    resetLink() {
      return process.env.VUE_APP_FRONT_NEXT_URL + "/authentification/modifier-mon-mot-de-passe";
    },

    updateLink() {
      return process.env.VUE_APP_FRONT_NEXT_URL + "/authentification/modifier-mon-identifiant";
    },
  },
};
</script>

<style lang="scss" scoped>
.info_top {
  font-weight: 200;
  font-size: 0.8rem;
  &.error_info {
    color: red;
    font-style: italic;
    font-size: 0.7rem;
  }
}

.info_identifiant {
  color: #3a1e00 !important;
  font-weight: bold;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}

.text_ifentifiant {
  font-family: "Roboto" !important;
  font-size: 16px !important;
}

.btn_identifiant {
  width: 210px !important;
  height: 34px !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}
</style>
