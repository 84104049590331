<template>
  <div style="min-width: 600px; max-width: 1000px; margin-top: 20px;">
    <p style="font-weight: bold;">
      Au cours de la saison, la FFME communique auprès de ses licenciés par
      courriel. Vous pouvez modifier ci-dessous vos préférences d'inscription à
      ces communications :
    </p>
    <ul style="list-style: unset; font-size: 14px;" id="example-1">
      <li>
        <div class="li_item">
          <p>
            Je souhaite recevoir la newsletter mensuelle Direct'Infos ainsi que
            les communications liées aux services et produits de la FFME (<a
              href="http://track.news.ffme.fr/track/webcopy/f4cf19dd6bfbef6d87f5d7b7fdfef4bb/13115992/566115"
              >voir le dernier Direct'infos</a
            >) :
          </p>
          <div class="item">
            <p class="input__label">NON</p>
            <v-switch v-model="newsletter.valueDirectInfo">
              <template v-slot:label>
                <span class="input__label">OUI</span>
              </template>
            </v-switch>
          </div>
        </div>
      </li>
      <li>
        <div class="li_item">
          <p class="input__label">
            Je souhaite recevoir les communications et bons plans proposés par
            les partenaires de la FFME :
          </p>
          <div class="item">
            <p class="input__label">NON</p>
            <v-switch v-model="newsletter.valuePartner">
              <template v-slot:label>
                <span class="input__label">OUI</span>
              </template>
            </v-switch>
          </div>
        </div>
      </li>
      <li>
        <div class="li_item">
          <p>
            J'autorise les ligues, comités territoriaux et clubs affiliés à la
            FFME à utiliser mes données personnelles dans un cadre strictement
            identique à celui du règlement de la FFME. Toute autre utilisation
            devra nécessiter un recueil de votre accord par les structures
            concernées :
          </p>
          <div class="item">
            <p class="input__label">NON</p>
            <v-switch v-model="newsletter.valuePersonalData">
              <template v-slot:label>
                <span class="input__label">OUI</span>
              </template>
            </v-switch>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    newsletter: {
      valueDirectInfo: true,
      valuePartner: false,
      valuePersonalData: true,
    },
  }),

  computed: {
    ...mapGetters("user", ["currentUser", "currentUserId"]),
  },

  watch: {
    "newsletter.valueDirectInfo": function (newval) {
      this.newsletter.valueDirectInfo = newval;
      this.onSwitchChanged(this.newsletter);
    },
    "newsletter.valuePartner": function (newval) {
      this.newsletter.valuePartner = newval;
      this.onSwitchChanged(this.newsletter);
    },
    "newsletter.valuePersonalData": function (newval) {
      this.newsletter.valuePersonalData = newval;
      this.onSwitchChanged(this.newsletter);
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getUtilisateurFullData"]),

    onSwitchChanged(newletter) {
      this.$emit("newsletterValues", newletter);
    },

    async setData() {
      this.newsletter.valueDirectInfo = this.currentUser?.EST_CommunicationDirectInfo;
      this.newsletter.valuePartner = this.currentUser?.EST_CommunicationPartenaire;
      this.newsletter.valuePersonalData = this.currentUser?.EST_UtilisationInfoPersonnel;
    },
  },

  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.input__label {
  font-family: Roboto;
  color: black;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-left: 10px !important;
  padding-bottom: 10px !important;
}
</style>
