<template>
  <UserCertificatMedicalForm :context="{ ...users, ID_Utilisateur: currentUserId }" />
</template>

<script>
import UserCertificatMedicalForm from "@/components/User/Form/User__FormCertificatMedical.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    UserCertificatMedicalForm,
  },

  data: () => ({
    title_section: "Certificats médicaux",
    users: null,
  }),

  computed: {
    ...mapGetters("user", ["currentUserId"]),
  },

  methods: {
    ...mapActions("utilisateurs", ["getUtilisateur"]),
  },


  async created() {
    this.users = await this.getUtilisateur({
      ID_Utilisateur: this.currentUserId,
    });
    console.log("userssssssssss ", this.users);
    this.$emit("setTitle", this.title_section);
  },
};
</script>
