<template>
  <validateForm :showFieldObl="false" @validated="submit">
    <NewsLetter @newsletterValues="onSwitchChanged($event)" />
    <div>
      <v-btn type="submit" class="btn btn-primary">Mettre à jour</v-btn>
    </div>
  </validateForm>
</template>

<script>
import NewsLetter from "@/components/User/Form/User__FormNewsLetter.vue";
import { mapActions, mapGetters } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import router from "@/router";
import validateForm from "@/components/utils/form";

export default {
  components: {
    NewsLetter,
    validateForm,
  },

  data: () => ({
    title_section: "Abonnements",
    newsletter: {
      valueDirectInfo: true,
      valuePartner: false,
      valuePersonalData: true,
    },
  }),

  computed: {
    ...mapGetters("user", ["currentUserId", "currentUser"]),
  },

  methods: {
    ...mapActions("utilisateurs", ["updateUserAbonnement"]),

    onSwitchChanged(newsletterValues) {
      this.newsletter = newsletterValues;
    },

    async submit() {
      try {
        delete this.user.ADR_Adresse;
        delete this.user.ACTI_ActivitePratiquees;
        delete this.user.ADR_Pays;
        delete this.user.__typename;
        delete this.user.DOC_Documents;
        delete this.user.UTI_Licence;
        this.user.EST_CommunicationDirectInfo = this.newsletter.valueDirectInfo;
        this.user.EST_CommunicationPartenaire = this.newsletter.valuePartner;
        this.user.EST_UtilisationInfoPersonnel = this.newsletter.valuePersonalData;
        const tmpUser = {
          id: this.user.id,
          EST_CommunicationDirectInfo: this.newsletter.valueDirectInfo,
          EST_CommunicationPartenaire: this.newsletter.valuePartner,
          EST_UtilisationInfoPersonnel:this.newsletter.valuePersonalData,
        }
        const {
          EST_CommunicationDirectInfo,
          EST_CommunicationPartenaire,
          EST_UtilisationInfoPersonnel,
        } = await this.updateUserAbonnement({
          user: tmpUser,
        });
        this.$store.commit("user/UPDATE_USER_NEWS_LETTER", {
          EST_CommunicationDirectInfo,
          EST_CommunicationPartenaire,
          EST_UtilisationInfoPersonnel,
        });
        success_notification("Vos informations ont été mises à jour");
        return router.push({ name: "Home" });
      } catch (e) {
        console.log(e);
        error_notification();
      }
    },
  },

  async created() {
    this.$emit("setTitle", this.title_section);
    this.user = { ...this.currentUser };
    console.log("this.user", this.user);
  },
};
</script>
