<template>
  <PAPList :context="{ ID_Utilisateur: currentUserId }" edit />
</template>

<script>
import PAPList from "@/components/Pap/PAP__List.vue";
import { mapGetters } from "vuex";

export default {
  components: { PAPList },

  data() {
    return {
      title_section: "Personne à prevenir en cas d'incident",
    };
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
  },

  async created() {
    this.$emit("setTitle", this.title_section);
  },
};
</script>
