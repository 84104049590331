<template>
  <v-row v-if="mounted">
    <v-col cols="2"></v-col>
    <v-col cols="10">
      <v-row>
        <v-col cols="12" class="text-center pt-12" style="width: 80%">
          <h3 style="margin-right: 300px">
            Bonjour {{ currentUser.CT_Prenom }}
          </h3>
          <p style="width: 80%; text-align: left">
            Ceci est votre première connexion. Il est nécessaire de remplir les
            champs suivants afin d’accéder à votre espace personnel myFFME.
          </p>
        </v-col>
        <v-col cols="12" class="d-flex">
          <div style="min-width: 600px; min-height: 150vh">
            <validateForm :showFieldObl="false" @validated="submit">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="civilité"
              >
                <v-row align-content="center">
                  <v-col cols="12" md="3" class="label_column">
                    <label for="">Sexe: </label>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-radio-group v-model="user.CT_EST_Civilite" row dense>
                      <v-radio label="Homme" :value="1"></v-radio>
                      <v-radio label="Femme" :value="0"></v-radio>
                    </v-radio-group>
                    <span class="input__error-message">{{ errors[0] }}</span>
                  </v-col>
                </v-row>
              </validation-provider>
              <inputText v-model="user.CT_Nom" rules="required" label="Nom" />
              <inputText
                v-model="user.CT_NomDeNaissance"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom de naissance"
              />
              <br />
              <inputText
                v-model="user.CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              />
              <datePicker
                v-model="user.DN_DateNaissance"
                :maxToday="true"
                @onUpdate="updateDate"
                rules="required"
                label="Date de naissance"
              />
              <countrySelect
                v-model="user.ID_PaysNationalite"
                rules="required"
                label="Nationalité"
              />
              <br />
              <CommuneAutocomplete
                v-model="user.DN_CommuneNaissance"
                onlyCities
                :item="user.DN_CommuneNaissance"
                rules="required"
                @onSelected="communeSelected"
                label="Commune de naissance"
              />
              <NewsLetter @newsletterValues="onSwitchChanged($event)" />
              <div>
                <v-btn type="submit" class="btn btn-primary"
                  >Confirmer les informations</v-btn
                >
              </div>
            </validateForm>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validateForm from "@/components/utils/form";
import inputText from "@/components/utils/input";
import countrySelect from "@/components/utils/select__countries";
import CommuneAutocomplete from "@/components/utils/adresseAutocomplete";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import router from "@/router";
import NewsLetter from "@/components/User/Form/User__FormNewsLetter.vue";

export default {
  components: {
    datePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),

    validateForm,
    inputText,
    countrySelect,
    CommuneAutocomplete,
    NewsLetter,
  },

  data: () => ({
    title_section: "Premiere connexion",
    user: {},
    communeNaissanceSelected: "",
    mounted: false,
    newsletter: {
      valueDirectInfo: true,
      valuePartner: false,
      valuePersonalData: true,
    },
  }),

  computed: {
    ...mapGetters("user", ["currentUser"]),
  },

  async created() {
    this.$emit("setTitle", this.title_section),
      (this.user = { ...this.currentUser });
    console.log("this.user", this.user);
    this.mounted = true;
  },

  methods: {
    ...mapActions("utilisateurs", ["updateUser"]),
    ...mapActions("user", ["updateUserPremiereConnexion"]),
    ...mapActions("basics", ["insertNewLetter", "insertNewLetterUser"]),

    async communeSelected(item) {
      this.user.DN_CommuneNaissance = item[0].long_name;
      await this.getCodeInsee(item[0].long_name);
    },

    updateDate(date) {
      this.user.DN_DateNaissance = date;
    },

    async getCodeInsee(city_name) {
      const fetch = require("node-fetch");

      (async () => {
        const response = await fetch(
          "https://api-adresse.data.gouv.fr/search/?q=" +
            city_name +
            "&type=municipality&autocomplete=0",
          {
            method: "get",
          }
        );
        const json = await response.json();
        if (json.features.length) {
          this.user.DN_CommuneNaissance_CodeInsee =
            json.features[0].properties.id;
        }
        // console.log(json.features)
        //console.log(json);
      })();

      return;
    },

    onSwitchChanged(newsletterValues) {
      this.newsletter = newsletterValues;
    },

    async submit() {
      try {
        delete this.user.ADR_Adresse;
        delete this.user.ACTI_ActivitePratiquees;
        delete this.user.ADR_Pays;
        delete this.user.__typename;
        delete this.user.DOC_Documents;
        delete this.user.UTI_Licences;

        this.user.Z_DatePremiereConnexion = new Date();

        if (typeof this.user.ID_PaysNationalite == "object") {
          this.user.ID_PaysNationalite = this.user.ID_PaysNationalite.id;
        }
        this.user.EST_CommunicationDirectInfo = this.newsletter.valueDirectInfo;
        this.user.EST_CommunicationPartenaire = this.newsletter.valuePartner;
        this.user.EST_UtilisationInfoPersonnel =
          this.newsletter.valuePersonalData;

        const userData = await this.updateUser({ user: this.user });
        await this.updateUserPremiereConnexion();
        // let newletter = await this.insertNewLetter({
        //     EST_Actif: true,
        //     NomNewsletter: "Newsletter Test",
        //     Ordre: i,
        //     Description: "",
        //     Frequence: "",
        //   })
        //   await this.insertNewLetterUser({
        //     newslettertype:{
        //         ID_Utilisateur: this.user.id,
        //         ID_Newsletter: newletter.insert_LIST_NewsletterType.returning[0].id
        //     },
        //   })
        success_notification("Vos informations ont été mises à jour");
        return router.push({ name: "Home" });
      } catch (e) {
        console.log(e);
        error_notification();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>
